import { Box, HStack, Text } from '@swftbox/style-guide';
import { useCallback, useEffect } from 'react';
import { VerticalTabs } from 'src/components/Organisms';
import { Feature, Retailer } from 'src/components/Particles';
import {
  AccountConfigSubtabsEnum,
  AllSubtabsEnum,
  MainTabsEnum,
  subtabMapping,
} from '../../Helper';
import {
  EmailSettings,
  NotificationSettings,
  OrderSettings,
  ProcedureSettings,
  StorfoxIntegration,
} from './Components';

interface Props {
  retailer: Retailer;
  categorizedFeatures: Record<string, Feature[]>;
  setAllowToMove: React.Dispatch<React.SetStateAction<{ page: string; isAllowed: boolean }>>;
  allowToMove: { page: string; isAllowed: boolean };
  activeTab: AllSubtabsEnum;
  setActiveTab: React.Dispatch<React.SetStateAction<AllSubtabsEnum>>;
}
export const AccountConfiguration = ({
  retailer,
  categorizedFeatures,
  activeTab,
  setActiveTab,
  setAllowToMove,
  allowToMove,
}: Props) => {
  const handleChangeTab = useCallback(
    (tabId: string) => {
      if (!allowToMove?.isAllowed) {
        setAllowToMove({ isAllowed: false, page: tabId });
      } else {
        setActiveTab(tabId as AccountConfigSubtabsEnum);
      }
    },
    [allowToMove]
  );
  const accountConfigTabs = subtabMapping[MainTabsEnum.AccountConfig];
  useEffect(() => {
    setActiveTab(AccountConfigSubtabsEnum.AccountSettings);
  }, []);
  return (
    <Box h="calc(100% - 50px)">
      <HStack align="start" h="100%" flexWrap="wrap">
        <Box position="fixed" top="125" left="5" zIndex={2} bg="#fff" h="100%" w="165px">
          <VerticalTabs
            tabs={accountConfigTabs}
            handleChange={handleChangeTab}
            active={activeTab}
          />
        </Box>
        {/* <Box w="100%" mb="5" position="sticky" top="0" background="#fff" zIndex="1">
          <Text fontWeight="semibold">Account Configuration</Text>
          <Text fontSize="text-sm" color="gray.600">
            Fine-tune Control Room account-wide settings
          </Text>
        </Box> */}
        <Box flexGrow="1" h="100%" ps="210px" pt="1">
          {activeTab === AccountConfigSubtabsEnum.AccountSettings && (
            <OrderSettings
              id={retailer.id}
              isDraftEnabled={retailer.isDraftEnabled}
              isMultiLocationAllowed={retailer.isMultiLocationAllowed}
              bagCollection={retailer.bagCollection}
              isManualPickupEnabled={retailer.isManualPickupEnabled}
              fulfillment={retailer.fulfillment}
              status={retailer.status}
              shippingLabelSize={retailer.shippingLabelSize}
              isPaymentLinksEnabled={retailer.isPaymentLinksEnabled}
              isLeaveAtTheDoorEnabled={retailer.isLeaveAtTheDoorEnabled}
              categoryIds={retailer.categoryIds}
              disableCardOnPayment={retailer.disableCardOnPayment}
              customerLocationMatchingStrategy={retailer.customerLocationMatchingStrategy}
              forceExpressReturn={retailer.forceExpressReturn}
              retailerConfiguration={retailer.retailerConfiguration}
              revealOverviewPage={retailer.revealOverviewPage}
              setAllowToMove={setAllowToMove}
              retailerFeatures={retailer.features}
              accountSettingsFeatures={categorizedFeatures?.accountSettings || []}
              showBrand={retailer.showBrand}
            />
          )}
          {activeTab === AccountConfigSubtabsEnum.EmailSettings && (
            <EmailSettings
              id={retailer.id}
              emailManifest={retailer.emailManifest}
              incomingPickupEmail={retailer.incomingPickupEmail}
              emailDailyRecap={retailer.emailDailyRecap}
              setAllowToMove={setAllowToMove}
            />
          )}
          {activeTab === AccountConfigSubtabsEnum.NotificationTriggers && (
            <NotificationSettings retailerId={retailer.id} setAllowToMove={setAllowToMove} />
          )}
          {activeTab === AccountConfigSubtabsEnum.InventorySettings && (
            <StorfoxIntegration retailer={retailer} setAllowToMove={setAllowToMove} />
          )}
          {/* {activeTab === AccountConfigSubtabsEnum.InventorySettings && (
            <DemoSettings retailer={retailer} setAllowToMove={setAllowToMove} />
          )} */}
          {activeTab === AccountConfigSubtabsEnum.SopsSettings && (
            <ProcedureSettings retailer={retailer} />
          )}
        </Box>
      </HStack>
    </Box>
  );
};
