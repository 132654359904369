import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, HStack, SBModal, Text, toast } from '@swftbox/style-guide';
import { useItems, useOrderModalActions, useOrderModalContext } from 'src/components/Particles';
import {
  useCarriyoIntegrationCarriersQuery,
  useAssignOrderInternational,
  type AssignInternationalInput,
  type Order,
} from 'src/components/Particles/resolvers/orders';
import { FormProvider, useForm } from 'react-hook-form';
import SVG from 'react-inlinesvg';
import InfoIcon from 'src/assets/icons/General/info-circle.svg';
import { OrderItemsModal } from 'src/components/Pages/Fullfillment/Components';
import CarriersForm from './Carriers.form';
import PackageDetailsForm from './PackageDetails.form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InternationalShipmentSchema } from './InternationalShipment.schema';

export function InternationalShipment() {
  const [step, setStep] = useState(1);

  const { internationalShipmentIsOpen, data: order } = useOrderModalContext() as {
    internationalShipmentIsOpen: boolean;
    data: Order;
  };

  const dispatch = useOrderModalActions();

  const { Items, getItems, loading: itemsLoading } = useItems();
  const { carriyoCarriers } = useCarriyoIntegrationCarriersQuery();
  const { assignOrderInternational, loading } = useAssignOrderInternational();

  const methods = useForm<AssignInternationalInput>({
    mode: 'all',
    resolver: yupResolver(InternationalShipmentSchema),
    defaultValues: {
      orderId: order.id,
    },
  });

  useEffect(() => {
    if (order.id && internationalShipmentIsOpen) {
      getItems(order.id);

      methods.reset({
        orderId: order.id,
      });
    }
  }, [getItems, methods, order.id, internationalShipmentIsOpen]);

  const handleCloseModal = useCallback(
    (message?: string) => {
      if (message) {
        if (message.includes('Error')) toast.error(message);
        else toast.success(message);
      }
      methods.reset();
      setStep(1);
      dispatch({ type: 'CLOSE_INTERNATIONAL_SHIPMENT_REQUEST' });
    },
    [dispatch, methods]
  );

  const onConfirm = useCallback(
    (data: any) => {
      void assignOrderInternational({
        assignOrderInternational: {
          ...data,
        },
        onCompleted: (message) => {
          if (message) {
            handleCloseModal(message);
          }
        },
      });
    },
    [assignOrderInternational, handleCloseModal]
  );

  const goBack = useCallback(() => {
    if (step === 2) {
      setStep(1);
    } else if (step === 1) {
      handleCloseModal();
    }
  }, [step, handleCloseModal]);

  const goNext = useCallback(async () => {
    if (step === 1) {
      const isValid = await methods.trigger('carrierId');
      if (!isValid) return;

      setStep(2);
    } else if (step === 2) {
      void methods.handleSubmit(onConfirm)();
    }
  }, [step, methods, onConfirm]);

  const isNotValidItems = useMemo(() => {
    if (itemsLoading) return false;
    return !Items.length || Items.some((item) => item.price === undefined || item.price === null);
  }, [Items, itemsLoading]);

  return (
    <SBModal
      isOpen={internationalShipmentIsOpen}
      onClose={handleCloseModal}
      type="general"
      header={
        <Box pb="1vh">
          <Text>International Shipment Request </Text>
        </Box>
      }
      body={
        <Box>
          {isNotValidItems && (
            <HStack
              border={`1px solid `}
              borderColor="brand.500"
              borderRadius="8px"
              p="8px"
              w="100%"
              bg="brand.50"
              marginBlock={3}
            >
              <SVG src={InfoIcon} color="#fbc70091" stroke="#000" />
              <Text fontWeight="semibold" fontSize="text-sm">
                Cannot request international deliveries with{' '}
                {!Items?.length
                  ? 'no items included in the order'
                  : 'items missing price or quantity'}
                .
              </Text>
            </HStack>
          )}
          <FormProvider {...methods}>
            <form>
              {step === 1 ? (
                <CarriersForm carriyoCarriers={carriyoCarriers} items={Items} />
              ) : (
                <PackageDetailsForm items={Items} currency={order.currency} />
              )}
            </form>
          </FormProvider>
        </Box>
      }
      size="4xl"
      footer={
        <HStack spacing="3" w="100%" justifyContent="space-between">
          <Button variant="outline" w="30%" onClick={goBack}>
            {step === 1 ? 'Dismiss' : 'Back'}
          </Button>
          <HStack w="70%" justifyContent="end">
            <OrderItemsModal
              orderId={order.id}
              viewItemsComponent={<Button w="35%">Edit Items</Button>}
            />
            <Button w="35%" isLoading={loading} onClick={goNext}>
              {step === 1 ? 'Next' : 'Confirm'}
            </Button>
          </HStack>
        </HStack>
      }
      // @ts-expect-error dummy
      scrollBehavior="outside"
    />
  );
}
